@media screen and (max-width: 1000px) {
    .display-two {
        text-align: center;
    }
    h3 {
        text-align: center;
    }
    h4 {
        text-align: center;
    }
    .button-group {
        align-items: center;
        align-content: center;
        text-align: center;
    }

}