h5 {
    margin:auto;
    padding:auto;
    width:60%;
    font-size: 30px;
}
.titles {
    color:orange
}

#utility{
    background-color: rgb(53, 52, 52);
    height:500px
}



#UtilityTitle {
    color:orange
}
@media screen and (min-width: 1000px) {
    .titles {
        color:orange;
    }
    h5 {
        margin:auto;
        padding:auto;
        width:60%;
        font-size: 20px;
    }
    #utilityText {
        width:40%;
        padding:auto;
        margin:auto
    }
    
    }

@media screen and (max-width: 1000px) {
.titles {
    color:orange;
}
h5 {
    margin:auto;
    padding:auto;
    width:60%;
    font-size: 20px;
}
#utilityText {
    width:60%;
    padding:auto;
    margin:auto
}

}

