@media screen and (max-width:800px) {
    #card {
        background-color:orange;
        height:30vh;
        width:50vw;
        overflow:hidden;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom:5px

    }

}

@media screen and (min-width: 801px) {
    #card {
        max-width:40rem;
        background-color:orange;
        height:400px;
        width:250px;
        overflow:hidden;
        margin-bottom:10px
      }
    
    
      
}
#cardBody {
    display:block
    }
    
    #card:hover #cardBody{
        display:block
    }
     #cardimg{
        height:100%;
        width:100%;
        transition: all 0.5s ease-in-out;
        position:relative;
        top:0px
      }
      
      #card:hover #cardimg {
        opacity:0;
        position:relative;
        top:500px;
        transition:all 0.1ms;
    
        
        overflow:hidden
      }
      
    #cardTitle{
        color:white;
    display:block;
    text-align: center;
      }
    
    
    #cardText{
    height:0px;
    opacity:0;
      }
    
      
    #card:hover #cardTitle {
        display:block;
        color:white;
        transition: all 0.5s ease-in-out;
      }
      
    #card:hover #cardText {
        transition: all 0.1s ease-in-out;
        display:block;
        color:white;
        opacity:1;
        height:100%;
        
    
      }
    
      
      
      
    .notUsedStuff {
        transition-delay: 0.1s;
        transition-property: opacity;
    }